import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import GetInTouch from "../../components/get-in-touch";
import RelatedProducts from "../../components/related-products";

interface Feature {
	title: string;
	description: string;
	viewBox: string;
	icon: string;
}

const AirlineBookingEnginePage = () => {
	const { t } = useTranslation();

	const FEATURES: Feature[] = [
		{
			title: t("Short Time to Market"),
			description: t(
				"short_time_to_market",
				"We can instantly deploy the solution within days by using ready-to-use cloud instances, configure the infrastructure and customize the design according to the brand guidelines. Easy."
			),
			viewBox: "0 0 650 700",
			icon:
				"M400 64h-48V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v52H160V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V160h352v298a6 6 0 0 1-6 6zm-52.849-200.65L198.842 404.519c-4.705 4.667-12.303 4.637-16.971-.068l-75.091-75.699c-4.667-4.705-4.637-12.303.068-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l44.104 44.461 111.072-110.181c4.705-4.667 12.303-4.637 16.971.068l22.536 22.718c4.667 4.705 4.636 12.303-.069 16.97z",
		},
		{
			title: t("Bundle"),
			description: t("The booking flow"),
			viewBox: "0 0 750 800",
			icon:
				"M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z",
		},
		{
			title: t("eCommerce Optimization"),
			description: t("We invested a lot into"),
			viewBox: "0 0 750 800",
			icon:
				"M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z",
		},
		{
			title: t("Seatmap"),
			description: t("Multi aircraft"),
			viewBox: "0 0 750 800",
			icon:
				"M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z",
		},
		{
			title: t("Cloud Operations"),
			description: t("Operated hundred percent in cloud providing high"),
			viewBox: "0 0 750 800",
			icon:
				"M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4z",
		},

		{
			title: t("Ancillaries"),
			description: t("Including various baggage"),
			viewBox: "0 0 530 580",
			icon:
				"M336 160H48c-26.51 0-48 21.49-48 48v224c0 26.51 21.49 48 48 48h16v16c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-16h128v16c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-16h16c26.51 0 48-21.49 48-48V208c0-26.51-21.49-48-48-48zm-16 216c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h240c4.42 0 8 3.58 8 8v16zm0-96c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h240c4.42 0 8 3.58 8 8v16zM144 48h96v80h48V48c0-26.51-21.49-48-48-48h-96c-26.51 0-48 21.49-48 48v80h48V48z",
		},
		{
			title: t("Payment"),
			description: t(
				"DEEP Airline IBE is PCI DSS certified providing the highest secure during credit card data transmitting"
			),
			viewBox: "0 0 750 800",
			icon:
				"M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z",
		},
	];

	const getInTouchArray: string[] = [
		t(
			"Are you struggling with an increasingly complex booking site development and need excessive time to add new features?"
		),
		t("Are you planning to launch your airline and have custom needs for the booking site?"),
		t("Are you about to re-design your web user journey but would like to skip the year-long internal project?"),
	];

	const data = useStaticQuery(graphql`
		query AirlineBookingEnginePageQuery {
			allFile(filter: { relativePath: { in: ["banner/product_ibe.jpg"] } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

	const fluidBanner = data.allFile.edges[0].node.childImageSharp.fluid;

	return (
		<Layout>
			<SEO title="Products" />
			<div className="text-brand-grayt">
				{/* Banner */}
				<BackgroundImage Tag="div" fluid={fluidBanner}>
					<div className="container text-white sm:pt-16 h-400px">
						<div className="md:w-4/5 lg:w-2/3 xl:w-1/2 px-4 highlight">
							<h1 className="pt-12 text-3xl sm:text-4xl leading-tight font-bold">
								<div>DEEP</div>
								<span>
									<Trans>Airline Internet Booking Engine</Trans>
								</span>
							</h1>
							<p className="text-lg mt-5 pb-24">
								<span>
									<Trans i18nKey="We believe in ready-to-use software products">
										We believe in ready-to-use software products combined with enterprise grade
										custom development service. That’s why we are keen to build convenient software
										solutions for our airline, tour operator and OTA partners.
									</Trans>
								</span>
							</p>
						</div>
					</div>
				</BackgroundImage>
				{/* Intro */}
				<div id="intro">
					<div className="container p-4 pt-8">
						<h1 className="font-bold text-2xl pb-6">
							<Trans>Compatible with Major Passenger Service Systems</Trans>
						</h1>
						<p className="text-lg pb-4">
							<Trans i18nKey="At a startup airline there are">
								At a start-up airline there are many challenges need to be solved before the launch
								including major IT and e-commerce decisions on the way. A significant task is always to
								build a modern website as quickly as possible that is capable to be extended in the
								future with complex features.
							</Trans>
						</p>

						<p className="text-lg pb-4">
							<Trans i18nKey="In order to overcome this">
								In order to overcome this issue the best solution usually is to create a custom
								developed booking engine that is modern, serves all of the business needs and is
								compatible with the selected Passenger Service System (PSS), although this is not a
								quick and low-cost way to launch.
							</Trans>
						</p>

						<p className="text-lg pb-4">
							<Trans i18nKey="DEEP Airline Internet">
								DEEP Airline Internet Booking Engine is developed from the ground-up using the latest
								technologies. Our team has learned from the market and designed the product to meet the
								needs of the modern business demands. We created a booking engine that is lightweight
								and can grow simultaneously with the business to handle complexity.
							</Trans>
						</p>

						<p className="text-lg p-4 pb-4 mt-2 mb-4 bg-brand-gray4 inline-block">
							<q>
								<Trans i18nKey="DEEP Airline IBE is">
									DEEP Airline IBE is fully mobile friendly, providing bundle options, seat selection,
									flexible ancillaries and integrated payment. Just what is needed.
								</Trans>
							</q>
						</p>
					</div>
				</div>
			</div>
			{/* Features */}
			<div id="features" className="bg-brand-gray4 pb-8">
				<div className="container p-4 pt-8">
					<h1 className="uppercase font-bold text-2xl mb-10">
						<span className="text-brand-green mr-2">//</span>
						<Trans>Features</Trans>
					</h1>
					<div className="flex flex-wrap text-brand-grayt">
						{FEATURES.map((feature) => (
							<div className="w-full md:w-1/2 flex" key={feature.title}>
								<div className="mt-2 mr-2">
									<svg
										className="w-14 fill-current"
										viewBox={feature.viewBox}
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d={feature.icon} />
									</svg>
								</div>
								<div>
									<div className="font-semibold text-xl">{feature.title}</div>
									<div className="mt-2 mr-4 lg:mr-12 mb-6 text-lg">{feature.description}</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<GetInTouch getInTouchArray={getInTouchArray} />
			<RelatedProducts missing="ibe" />
		</Layout>
	);
};

export default AirlineBookingEnginePage;
